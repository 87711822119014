import { useLazyQuery } from '@apollo/client';
import { CustomerOnboardingState, User } from 'lib/generated/graphql';
import { GET_COMPANY_QUERY, GET_USER_QUERY } from 'lib/queries';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { FunctionComponent, useEffect, useState } from 'react';
import useAuth from 'src/shared-components/Auth';
import PageContainer from 'src/shared-components/Layout/PageContainer';

const Company = dynamic(() => import('src/screens/company'));
const UserProfile = dynamic(() => import('src/screens/user'));

interface ProfileProps {}

const Profile: FunctionComponent<ProfileProps> = () => {
  const { user, refetch } = useAuth();
  const [userData, setUserData] = useState<User>();

  const router = useRouter();
  const [getUser, { loading: userLoading, error: userError }] = useLazyQuery(GET_USER_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (!data.getUser) {
        router.push(`/huddles/${router.query.slug}`);
      } else {
        setUserData(data.getUser);
      }
    },
  });

  const [getCompany, { loading: companyLoading, error: companyError, data: companyData }] = useLazyQuery(
    GET_COMPANY_QUERY,
    {
      fetchPolicy: 'network-only',
      onCompleted: data => {
        if (!data.getCompany) {
          getUser({
            variables: {
              getUserData: {
                username: router.query.slug,
              },
            },
          });
        }
      },
    },
  );

  useEffect(() => {
    const { slug } = router.query;

    if (slug) {
      getCompany({
        variables: {
          getCompanyData: {
            slug,
          },
        },
      });
    }
  }, [router.query]);

  if (companyLoading || userLoading) <>Loading...</>;

  // TODO: create a component for errors
  if (companyError || userError) <>{JSON.stringify(companyError)}</>;

  return (
    <PageContainer fullWidth titleOnlySmallScreens>
      {companyData?.getCompany && companyData?.getCompany.slug === router.query.slug && (
        <Company
          company={companyData.getCompany}
          customerOnboardingState={user?.customerOnboarding?.state || CustomerOnboardingState.Init}
          isCurrentUserAllowedToEdit={user?.username === companyData.getCompany.customer.username}
          onCompanyUpdated={slug => {
            // console.log('enter here', slug);
            // router.push(`/${slug}`);
            getCompany({
              variables: {
                getCompanyData: {
                  slug,
                },
              },
            });
          }}
        />
      )}
      {userData && userData.username === router.query.slug && (
        <UserProfile
          user={userData}
          isCurrentUserOwner={user?.username === userData.username}
          onUserUpdated={usernameUpdated => {
            // Refetch is not working, I had to re-call the query with a fetch policy
            // https://github.com/apollographql/react-apollo/issues/4000
            getUser({
              variables: {
                getUserData: {
                  username: usernameUpdated,
                },
              },
            });
            refetch();
          }}
        />
      )}
    </PageContainer>
  );
};

export default Profile;
