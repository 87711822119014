import { MenuRounded } from '@material-ui/icons';
import { Role } from 'lib/generated/graphql';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import useAuth from 'src/shared-components/Auth';
import { useDashboardNav } from 'src/shared-components/Layout/DashboardLayout';

const PageContainer = ({
  title,
  fullWidth = false,
  children,
  tabs,
  titleOnlySmallScreens = false,
  sideButton,
}: {
  title?: string;
  fullWidth?: boolean;
  children: ReactNode;
  tabs?: { href: string; label: string }[];
  titleOnlySmallScreens?: boolean;
  sideButton?: JSX.Element;
}) => {
  const { setSideMenuOpen } = useDashboardNav();
  const router = useRouter();
  const { user } = useAuth();

  return (
    <div className={user?.role === Role.Customer && 'px-20'}>
      <div className={`flex flex-col min-h-screen py-8 px-4 lg:p-12 lg:pb-0 overflow-x-hidden`}>
        <div className="flex flex-row items-center gap-x-4">
          <div className="block lg:hidden">
            <MenuRounded onClick={() => setSideMenuOpen(true)} fontSize="large" aria-hidden="true" />
          </div>
          {title && (
            <>
              <h1 className={`text-2xl lg:text-4xl font-medium ${titleOnlySmallScreens && 'lg:hidden'}`}>{title}</h1>
            </>
          )}
          {sideButton && <div className="ml-auto">{sideButton}</div>}
          {tabs?.length > 0 &&
            tabs.map(tab => {
              return (
                <Link key={tab.label} href={tab.href}>
                  <h1
                    className={`text-2xl lg:text-4xl sm:mr-16 font-medium cursor-pointer ${
                      tab.href === router.pathname ? 'text-black' : 'text-gray-300'
                    }`}
                  >
                    {tab.label}
                  </h1>
                </Link>
              );
            })}
        </div>
        <div className={`${!titleOnlySmallScreens && 'mt-8'} ${fullWidth ? 'w-full-ignore-padding-14' : ''} `}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default PageContainer;
